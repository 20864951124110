import React from "react";
import "./footer.scss";
import { currentYear } from "../helpers";

const Footer = () => {
  return (
    <footer>
      stefantut.dev | <span>{currentYear()}</span>
    </footer>
  );
};

export default Footer;
