export function currentYear() {
  const date = new Date(),
    year = date.getFullYear();
  return year;
}

export function myAge() {
  const bornYear = 1989,
    myAge = currentYear() - bornYear;
  return myAge;
}
