import React from "react";
import "./firstSection.scss";

const FirstSection = () => {
  return (
    <section className="home-first-section">
      <h2>
        Hey, I'm <span>Stefan</span>
      </h2>
      <h3>A Full Stack Software Developer based in Sheffield, UK</h3>
    </section>
  );
};

export default FirstSection;
