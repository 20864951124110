import React from "react";
import ReactTooltip from "react-tooltip";
import "./description.scss";

import { ReactComponent as GoogleLogo } from "../images/google.svg";
import { ReactComponent as UdacityLogo } from "../images/udacity.svg";

import { myAge } from "../helpers";

import useWindowSize from "../hooks/useWindowSize";

const Description = ({ showGame }) => {
  const size = useWindowSize(),
    projects = [
      {
        name: "Sorting Visualizer",
        url: "https://sorting-visualizer.stefantut.dev",
        description:
          "Desktop application built with React - Merge Sort visualizer using React Hooks.",
      },
      {
        name: "Movies Library App",
        url: "https://moviesapp.stefantut.dev",
        description:
          "Will display all movies from The Movie Database API and all the details about each movie.<br>The user can either filter all the movies by genres or use the search field to find a specific movie.<br>Has a custom pagination which will fetch a different endpoint for each functionality, either on load, Search results or Genres filter.<br>I have used React, React Router, FetchAPI, Styled Components, and PropTypes.",
      },
      {
        name: "Whack A Mole Game",
        url: "https://whack-a-mole.stefantut.dev",
        description:
          "Final project for Javascript30 Course.<br>Added extra: Local Storage, User name input field with validation, Replaced mouse icon, High Score Array sorted by points, Display only top 5 users from highscore, Time Left countdown, 2 different durations of the game.",
      },
      {
        name: "To Do App",
        url: "https://todo.stefantut.dev",
        description:
          "Simple To Do List App Built using React Hooks and functional components.<br>The app is fully responsive. Used Jest/Enzyme for testing components.",
      },
      {
        name: "Characters App",
        url: "https://charactersapp.stefantut.dev",
        description:
          "Create your own virtual characters.<br> Built with Vue.js. Integrated Snipcart payments.",
      },
      {
        name: "Calculator",
        url: "https://calculator.stefantut.dev",
        description: "A simple calculator built with Vanilla JS.",
      },
      {
        name: "Corona Stats App",
        url: "https://corona-app.stefantut.dev",
        description:
          "Retrieves all data from API.<br>Used CSS Grid and made it fully responsive without using media queries.",
      },
      {
        name: "Catch of the Day",
        url: "https://catch-of-the-day.stefantut.dev",
        description:
          "Final project - React for Begginers Course (Class Components). Built a restaurant menu, an order form, and an inventory management area where authorized users can immediately update product details. Used Firebase to store data.",
      },
    ];

  const projectsList = projects.map((project, index) => (
    <li key={index} className="project" data-tip={project.description}>
      <a href={project.url} target="_blank" rel="noreferrer">
        {project.name}
      </a>
    </li>
  ));

  return (
    <div className="description">
      {/* <p>I am a {myAge()}-years-old software developer who loves coding.</p> */}
      <p>I am a software developer who loves coding.</p>
      <p>
        My journey started in 2018 when I received a scholarship from
        {size.width > 1000 || size.height > 800 ? (
          <span className="logo logo--google">
            <GoogleLogo title="Google" />
          </span>
        ) : (
          " Google"
        )}
        , followed by a Full Nanodegree from
        {size.width > 1000 || size.height > 800 ? (
          <span className="logo logo--udacity">
            <UdacityLogo title="Udacity" />
          </span>
        ) : (
          " Udacity"
        )}
        .
      </p>
      <p>
        Having found my passion, this changed my life completely. It has been a
        continuous process of learning and improving my skills since then.
      </p>
      <br />
      <p>
        My goal is to make a better web. One that is fast, easy to use,
        beautiful and accessible to all.
      </p>
      <br />
      <p>I work with HTML, CSS and JavaScript.</p>
      <p>
        At the moment I am focusing on React, Ant Design, SCSS, Styled
        Components, TypeScript, Node, Express, AWS and Serverless Applications.
      </p>
      {/* <div className="projects-container">
        <p>Here are a few of my personal projects:</p>
        <ReactTooltip
          place="right"
          effect="solid"
          multiline={true}
          textColor="var(--white)"
          border={true}
          borderColor="var(--secondary-color)"
          className="tooltip"
        />
        <ul className="projects">{projectsList}</ul>
      </div> */}

      <button onClick={() => showGame()} className="play-btn">
        Play again?
      </button>
    </div>
  );
};

export default Description;
