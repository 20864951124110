import React, { useState } from "react";
import "./secondSection.scss";

import Description from "./Description";
import MemoryGame from "./MemoryGame";

const SecondSection = ({ setActiveConfetti }) => {
  const [isGameVisible, setIsGameVisible] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("isGameVisible");
    const initialValue = saved ? JSON.parse(saved) : true;
    return initialValue;
  });

  const hideGame = () => {
    localStorage.setItem("isGameVisible", "false");
    setIsGameVisible(false);
  };
  const showGame = () => {
    localStorage.setItem("isGameVisible", "true");
    setIsGameVisible(true);
  };

  return (
    <section className="second-section">
      {isGameVisible ? (
        <MemoryGame hideGame={hideGame} setActiveConfetti={setActiveConfetti} />
      ) : (
        <Description showGame={showGame} />
      )}
    </section>
  );
};

export default SecondSection;
