import React, { useState, useEffect } from "react";
import "./memoryGame.scss";

import SingleCard from "./SingleCard";

const cardImages = [
  { src: "/img/circle.png", matched: false },
  { src: "/img/square.png", matched: false },
  { src: "/img/triangle.png", matched: false },
  { src: "/img/rectangle.png", matched: false },
];

const MemoryGame = ({ hideGame, setActiveConfetti }) => {
  const [cards, setCards] = useState([]),
    [choiceOne, setChoiceOne] = useState(null),
    [choiceTwo, setChoiceTwo] = useState(null),
    [isDisabled, setIsDisabled] = useState(false),
    [turns, setTurns] = useState(0),
    [timeLeft, setTimeLeft] = useState(null),
    shuffleCards = () => {
      const shuffledCards = [...cardImages, ...cardImages]
        .sort(() => Math.random() - 0.5)
        .map((card) => ({ ...card, id: Math.random() }));

      setChoiceOne(null);
      setChoiceTwo(null);
      setCards(shuffledCards);
      setTurns(0);
    },
    resetChoices = () => {
      setChoiceOne(null);
      setChoiceTwo(null);
      setTurns((pTurns) => pTurns + 1);
      setIsDisabled(false);
    };

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setIsDisabled(true);
      if (choiceOne.src === choiceTwo.src) {
        setCards((prevCards) => {
          return prevCards.map((card) => {
            if (card.src === choiceTwo.src) {
              return { ...card, matched: true };
            } else {
              return card;
            }
          });
        });

        resetChoices();
      } else {
        setTimeout(() => resetChoices(), 1000);
      }
    }
  }, [choiceOne, choiceTwo]);

  // handle a choice
  const handleChoice = (card) => {
    !choiceOne ? setChoiceOne(card) : setChoiceTwo(card);
  };

  useEffect(() => {
    shuffleCards();
  }, []);

  const allCardsAreMatched =
    cards.length > 0 &&
    cards.every((currentVal) => currentVal.matched === true);

  // Countdown before displaying description
  useEffect(() => {
    if (timeLeft === 1) {
      hideGame();
      shuffleCards();
      setTimeLeft(null);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, hideGame, setActiveConfetti]);

  useEffect(() => {
    if (allCardsAreMatched) {
      setTimeLeft(7);
      setActiveConfetti(true);
    }
  }, [cards, allCardsAreMatched, setActiveConfetti]);

  const message = (turns) => {
    if (turns <= 5) {
      return `That's great! Keep going 😉 | Turns: ${turns}`;
    } else if (turns >= 6 && turns <= 8) {
      return `You will need to focus more 😋 | Turns: ${turns}`;
    } else {
      return `Turns: ${turns}`;
    }
  };

  return (
    <div className="memory-game">
      {!timeLeft && (
        <>
          <p style={{ textAlign: "center" }}>
            You will have to play a little memory game if you want to find out
            more about me.
          </p>
          <div className="div card-grid">
            {cards.map((card) => (
              <SingleCard
                card={card}
                flipped={
                  card === choiceOne || card === choiceTwo || card.matched
                }
                key={card.id}
                handleChoice={handleChoice}
                disabled={isDisabled}
              />
            ))}
          </div>
        </>
      )}
      {turns > 0 && !timeLeft && (
        <div className="message">{message(turns)}</div>
      )}
      {timeLeft && (
        <div className="time-left">
          <p className="know">
            Soon you will find out more about me,
            <br />
            The only thing I know about you...
            <br />
            You need <span>{turns}</span> turns to finish a memory game :)
          </p>
          <p className="countdown">{timeLeft}</p>
        </div>
      )}
    </div>
  );
};

export default MemoryGame;
