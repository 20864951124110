import React, { useState, useEffect } from "react";
import "./home.scss";
import FirstSection from "../components/FirstSection";
import SecondSection from "../components/SecondSection";

import { ReactComponent as Square } from "../icons/square.svg";
import { ReactComponent as Circle } from "../icons/circle.svg";
import { ReactComponent as Triangle } from "../icons/triangle.svg";
import Contact from "../components/Contact";

import useWindowSize from "../hooks/useWindowSize";

import Confetti from "react-confetti";

const Home = () => {
  const [visibleAnimation, setVisibleAnimation] = useState(false),
    [activeAnimation, setActiveAnimation] = useState(false),
    size = useWindowSize(),
    [activeConfetti, setActiveConfetti] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisibleAnimation(true);
    }, 1500);
    setTimeout(() => {
      setActiveAnimation(true);
    }, 6500);
  }, []);

  return (
    <main className="section section__home">
      <Confetti
        width={size.width}
        height={size.height}
        run={activeConfetti}
        recycle={false}
        onConfettiComplete={(confetti) => {
          setActiveConfetti(false);
          confetti.reset();
        }}
      />
      <div className="home-content content">
        <Square
          className={`${
            visibleAnimation ? "square square__visible" : "square"
          } ${activeAnimation ? "square square__animating" : ""}`}
        />
        <Circle
          className={`${
            activeAnimation ? "circle circle__animating" : "circle"
          }`}
        />
        <Triangle
          className={`${
            visibleAnimation ? "triangle triangle__visible" : "square"
          } ${activeAnimation ? "triangle triangle__animating" : ""}`}
        />
        <FirstSection />
        <SecondSection setActiveConfetti={setActiveConfetti} />
        <Contact />
      </div>
    </main>
  );
};

export default Home;
