import React from "react";
import "./contact.scss";
import { ReactComponent as LinkedIn } from "../icons/linkedin.svg";
import { ReactComponent as Github } from "../icons/github.svg";

const Contact = () => {
  return (
    <section className="contact">
      <a
        href="mailto:hello@stefantut.dev"
        className="mail"
        title="Get in touch"
      >
        hello<span>@</span>stefantut.dev
      </a>
      <div className="social">
        <a
          href="https://linkedin.com/in/stefan-tutuianu"
          target="_blank"
          rel="noreferrer"
          title="LinkedIn"
        >
          <LinkedIn className="linkedin social-icon" />
        </a>
        <a
          href="https://github.com/stefantut"
          target="_blank"
          rel="noreferrer"
          title="View Code on Github"
        >
          <Github className="github social-icon" />
        </a>
      </div>
    </section>
  );
};

export default Contact;
