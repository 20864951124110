import React from "react";
import "./header.scss";
import { ReactComponent as Circle } from "../icons/circle.svg";
import { ReactComponent as Square } from "../icons/square.svg";
import { ReactComponent as Triangle } from "../icons/triangle.svg";

const Header = () => {
  return (
    <header className="header">
      <div className="header-content content">
        <div className="logo">
          <Square className="logo-image logo-image__square" />
          <Circle className="logo-image logo-image__circle" />
          <Triangle className="logo-image logo-image__triangle" />
        </div>
        <h1 className="job-title">Web & Software Development</h1>
      </div>
    </header>
  );
};

export default Header;
